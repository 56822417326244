.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.delay-200 { transition-delay: 200ms; }
.delay-400 { transition-delay: 400ms; }
.delay-600 { transition-delay: 600ms; }
